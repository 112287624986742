import React from "react"

import { Container, ImgContainer, Image } from "./article.css"

const Article = ({ data, createdDate }) => (
  <Container className="Article">
    <a href={`/articles/${data.slug}`}>
      <img src={data.picture} />
      <div class="articleText">
        <h3 class="articleTitle">{data.title}</h3>
        <p class="articleSummary">{data.summary ? data.summary.length > 300 ? data.summary.substring(0, 300) + '...' : data.summary : null}</p>
        <div class="articlePublish">
          <div class="articleAuthor">By {data.writer?.value?.data?.title || "The Editorial Team"}</div>
          <div class="articleDate">{new Intl.DateTimeFormat('en-HK', { month: 'short', day: '2-digit', year: 'numeric' }).format(createdDate)}</div>
        </div>
      </div>
    </a>
  </Container>
)

export default Article
