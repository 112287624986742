import React from "react"
import { Helmet } from "react-helmet"
import Article from "../components/BrandPage/article"
import { ArticlesContainer } from "../components/BrandPage/brand-page.css"
import { Container, Subheading, Divider, Image, Title } from "../components/Hero/hero.css"
import Newsletter from "../components/Newsletter/newsletter"
import BreadCrumb from "../components/Breadcrumb/breadcrumb"

function SectionPageTemplate({ pageContext }) {
  const { id, data, articles} = pageContext
  return (
    <>
      <Helmet>
        <title>{data.name}</title>
        <meta property="og:url" content={`https://www.watchesguild.com/sections/${data.code}`} />
        <meta property="og:description" content={data.description}  />
        <meta name="description" content={data.description} ></meta>
        <link rel="canonical" href={`https://www.watchesguild.com/sections/${data.code}`}/>
        <script type="application/ld+json">
          {`
          {"@context":"http://schema.org","@type":"BreadcrumbList","itemListElement":[
            {"@type":"ListItem","position":1,"item":{"@id":"https://www.watchesguild.com/sections","name":"Articles"}},
            {"@type":"ListItem","position":2,"item":{"@id":"https://www.watchesguild.com/sections/${data.code}","name":"${data.name}"}}
          ]}
          `}
        </script>
      </Helmet>
      <main
        style={{
          margin: `0 auto`,
        }} className="sectionPage stdPage">
        <div class="heroSection">
          <div class="heroContainer">
            <BreadCrumb section="Sections" sectionURL="/sections" pageName={data.name}/>
            <Container className="imageContainer container">
              <Title>{data.name}</Title>
              <p>{data.description}</p>
            </Container>
          </div>
        </div>
        <div class="container">
          <ArticlesContainer className="Articles fixSpace">
          {articles.map((article, index) => (
            <Article key={index} {...article} display="full"/>
          ))}
          </ArticlesContainer>
        </div>
        <Newsletter placeholder="Your email here" submit="Subscribe" />
      </main>
    </>
  )
}

export default SectionPageTemplate
